.navbar {
	width: rem(60px);
	max-height: 100vh;
	padding: var(--mantine-spacing-md);
	padding-top: 10px;
	flex-direction: column;
	/* border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)); */

	display: grid;
	grid-template-rows: auto 1fr auto;

	justify-items: center;
}

.navbarMain {
	flex: 1;
	margin-top: rem(50px);
}

.link {
	width: rem(40px);
	height: rem(40px);
	border-radius: var(--mantine-radius-md);
	display: flex;
	align-items: center;
	justify-content: center;
	color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));

	&:hover {
		background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
	}

	&[data-active] {
		&,
		&:hover {
			background-color: var(--mantine-color-blue-light);
			color: var(--mantine-color-blue-light-color);
		}
	}
}

.rounded {
	border-radius: 9999px;
}

.leader-shadow {
	/* --size: 8px; */

	/* width: calc(100% + var(--size)); */
	/* height: calc(100% + var(--size)); */
	/* position: absolute; */
	/* top: calc(-1 * var(--size) / 2); */
	/* left: calc(-1 * var(--size) / 2); */
	/* z-index: 1000; */
	/* border-radius: 9999px; */
	/* animation: glow 7s infinite; */

	/* border: 2px solid var(--mantine-color-grape); */

	border-radius: 6px;
	left: -34px;
	position: fixed;
	width: 20px;
	height: 120%;
	top: -10%;
	position: absolute;
	background-color: var(--mantine-color-blue-filled);
}

@keyframes glow {
	0% {
		opacity: 0.3;
	}

	50% {
		opacity: 0.8;
	}

	100% {
		opacity: 0.3;
	}
}
