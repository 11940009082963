.NavbarMinimal_navbar__o8KnE {
	width: calc(3.75rem * var(--mantine-scale));
	max-height: 100vh;
	padding: var(--mantine-spacing-md);
	padding-top: 10px;
	flex-direction: column;
	/* border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)); */

	display: grid;
	grid-template-rows: auto 1fr auto;

	justify-items: center;
}

.NavbarMinimal_navbarMain__Sv_js {
	flex: 1;
	margin-top: calc(3.125rem * var(--mantine-scale));
}

.NavbarMinimal_link__B_P3P {
	width: calc(2.5rem * var(--mantine-scale));
	height: calc(2.5rem * var(--mantine-scale));
	border-radius: var(--mantine-radius-md);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--mantine-color-gray-7);
}

[data-mantine-color-scheme='dark'] .NavbarMinimal_link__B_P3P {
	color: var(--mantine-color-dark-0);
}

.NavbarMinimal_link__B_P3P:hover {
		background-color: var(--mantine-color-gray-0);
	}

[data-mantine-color-scheme='dark'] .NavbarMinimal_link__B_P3P:hover {
		background-color: var(--mantine-color-dark-5);
}

.NavbarMinimal_link__B_P3P[data-active],
		.NavbarMinimal_link__B_P3P[data-active]:hover {
			background-color: var(--mantine-color-blue-light);
			color: var(--mantine-color-blue-light-color);
		}

.NavbarMinimal_rounded__ZPUzV {
	border-radius: 9999px;
}

.NavbarMinimal_leader-shadow___wpSD {
	/* --size: 8px; */

	/* width: calc(100% + var(--size)); */
	/* height: calc(100% + var(--size)); */
	/* position: absolute; */
	/* top: calc(-1 * var(--size) / 2); */
	/* left: calc(-1 * var(--size) / 2); */
	/* z-index: 1000; */
	/* border-radius: 9999px; */
	/* animation: glow 7s infinite; */

	/* border: 2px solid var(--mantine-color-grape); */

	border-radius: 6px;
	left: -34px;
	position: fixed;
	width: 20px;
	height: 120%;
	top: -10%;
	position: absolute;
	background-color: var(--mantine-color-blue-filled);
}

@keyframes NavbarMinimal_glow__eJb2S {
	0% {
		opacity: 0.3;
	}

	50% {
		opacity: 0.8;
	}

	100% {
		opacity: 0.3;
	}
}

